import {React, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Ad from "./Ad";
import Navbar from "./Navbar";
import Footer from "./Footer";
import InnerModal from "./InnerModal";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useCookies } from "react-cookie";
import Cennik from "./Cennik";
// import szkole from "../img/szkole.png";
import szkole from "../img/Szkolenia_small.jpg";
import opony from "../img/opony.jpg";
import pmp1 from "../img/foto.jpg";
import pmp2 from "../img/pmp2.png";
import pmp3 from "../img/pmp3.png";
import pmp4 from "../img/pakiet_zabezpieczenie.jpg";
import pmpfolia from "../img/Car Wrapping_small.jpg";
import sgb from "../img/sgb.png";
import sgf from "../img/sgf.png";
import gwi from "../img/gwi.png";
import ava from "../img/avatar.png";
import ReactSimplyCarousel from 'react-simply-carousel';
import uroaddesk from "../img/uroaddesk1.jpg";
import uroadmob from "../img/uroadmob1.jpg";
import homepagepromoauto from "../img/homepagepromo.png";

function importAll(r) {
    let images = {};
    r.keys().map((item) => ( images[item.replace('./', '')] = r(item)));
    return images;
}
const images = importAll(require.context('../kalad/', false, /\.(png|jpe?g|svg)$/));

const Home = () => {
    const navigate = useNavigate();
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [cookies, setCookie] = useCookies()
    const [open, setOpen] = useState(false);
    const [currentSelectedCat, setCurrentSelectedCat] = useState('Kosmetyka');
    const [openSecond, setOpenSecond] = useState(false);
    const [services, setServices] = useState();
    const [menuData, setMenuData] = useState();

    useEffect(()=>{
        fetch('https://test.cms.soft99.eu/garage-services')
            .then(resp => resp.json())
            .then((data) => {
                setServices(data);
            })
        fetch('https://test.cms.soft99.eu/garage-menu')
            .then(resp => resp.json())
            .then((data) => {
                setMenuData(data);
            })
        axios.post('https://cms.soft99.eu/auth/local',
            {
                identifier: "api",
                password: "VFSuniCEqJ295Pb$"
            }).then(response=>{
                setCookie('JWTToken', response.data.jwt, { path: '/' })
            })
    },[])

    const catCarouselFilter = (e, catToFilterName) => {
        console.log(e.target);
        // console.log(catToFilterName);
        setCurrentSelectedCat(catToFilterName)
    };

    const handleMapServices = () => {
        let temp = services;
        const listedServices = temp.map((service)=>{
            return <div key={service.id} style={{display: (service.garage_category.name === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }} onClick={()=>{navigate('/' + service.garage_category.path +"/"+service.path)}} className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" data-category={service.garage_category.name}>
                <div className="cat-carousel-card-inner">
                    <div className="cat-carousel-card-img">
                        <img src={"https://test.cms.soft99.eu" + service.image.formats.small.url} alt=""/>
                    </div>
                    <div className="cat-carousel-card-title">
                        <p>{service.title}</p>
                    </div>
                </div>
            </div>
        });
        return listedServices;
    }

    const handleMapServicesMobile = () => {
        let temp = services;
        const listedServices = temp.map((service)=>{
            return <div key={service.id} style={{display: (service.garage_category.name === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }} onClick={()=>{navigate('/' + service.garage_category.path +"/"+service.path)}} className="mob-cat-carousel-card col" data-category={service.garage_category.name}>
                <div className="mob-cat-carousel-card-inner">
                    <div className="mob-cat-carousel-card-img">
                        <img src={"https://test.cms.soft99.eu" + service.image.formats.small.url} alt=""/>
                    </div>
                    <div className="mob-cat-carousel-card-title">
                        <p>{service.title}</p>
                    </div>
                </div>
            </div>
        });
        return listedServices;
    }

    const handleMapAds = () => {
        let temp = menuData.ads;
        const listedServices = temp.map((ad)=>{
            return <Ad key={ad.id} ad={ad}/>
        });
        return listedServices;
    }

    const date = new Date();
    const Curdate = date.getDate();
    const AKdeskLink = 'AK_'+Curdate+'_kafle_desktop.jpg';
    const AKmobkLink = 'AK_'+Curdate+'_kafle_mobile.jpg';

    return(
        <div>
            <header>
                <Navbar services={services}/>
            </header>
            <Modal open={open} onClose={() => setOpen(false)} center>
                <InnerModal setOpen={setOpen}/>
            </Modal>
            <Modal open={openSecond} onClose={() => setOpenSecond(false)} center>
                <Cennik />
            </Modal>
            <section className="black-section homepage-welcome-screen">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Pasja i perfekcja</h1>
                        </div>
                        <div className="col-lg-12 res-box-mobileonly mobphonefix1">
                            <button onClick={() => setOpen(true)}>Umów się na wizytę</button>
                        </div>
                        <div className="col-lg-12 res-box-mobileonly mobphonefix2">
                            <a class="footer-active-link" href="tel:+48607285285">+48 607 285 285</a>
                        </div>
                        
                        <div className="col-lg-12 col-sm-12 cat-box">
                            <div className="cat-carousel">
                                <div className="cat-carousel-tags">
                                    {/* <button className={currentSelectedCat === 'Promo' ? 'active' : ''} onClick={(e) => catCarouselFilter(e, 'Promo')}>Popularne</button> */}
                                    <button className={currentSelectedCat === 'Kosmetyka' ? 'active' : ''} onClick={(e) => catCarouselFilter(e, 'Kosmetyka')}>Kosmetyka</button>
                                    <button className={currentSelectedCat === 'Serwis' ? 'active' : ''} onClick={(e) => catCarouselFilter(e, 'Serwis')}>Serwis</button>
                                    <button className={currentSelectedCat === 'Inne' ? 'active' : ''} onClick={(e) => catCarouselFilter(e, 'Inne')}>Inne</button>
                                </div>
                                <div className="cat-carousel-cards row">
                                    {/* nowy układ */}

                                    {/* K: KOSMETYKA */}

                                    {/* Detailing */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/kosmetyka/detailing')}} style={{display: ('Kosmetyka' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src="https://test.cms.soft99.eu/uploads/small_Detailing_kopiya_ecd0188c25.jpg" alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Detailing</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Myjnia */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/kosmetyka/myjnia')}} style={{display: ('Kosmetyka' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src="https://test.cms.soft99.eu/uploads/small_Myjnia_03c5ba8c14.png" alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Myjnia</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* Aplikacja folii */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/kosmetyka/folia')}} style={{display: ('Kosmetyka' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                {/* <p className="ybadge">Wymiana opon GRATIS!</p> */}
                                                <img src={pmpfolia} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Aplikacja folii</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* K: SERWIS */}
                                    
                                    {/* Zmiana opon */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/serwis/felgi-i-opony')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                {/* <p className="ybadge">Mycie GRATIS!</p> */}
                                                <img src={opony} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Zmiana opon</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Serwis mechaniczny */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/serwis/serwis-mechaniczny')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src="https://test.cms.soft99.eu/uploads/small_serwei_5a8624ace2.jpg" alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Serwis mechaniczny</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Badania techniczne */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/serwis/badania-techniczne')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src="https://test.cms.soft99.eu/uploads/small_Przeglady_okresowe_kopiya_c3714f4899.jpg" alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Badania techniczne</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Diagnostyka komputerowa */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/serwis/diagnostyka-komputerowa')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src="https://test.cms.soft99.eu/uploads/small_Diagnostyka_komputerowa_fccc53f051.png" alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Diagnostyka komputerowa</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Klimatyzacja */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/serwis/klimatyzacja')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src="https://test.cms.soft99.eu/uploads/small_Klimatyzacja_kopiya_6c54433add.jpg" alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Klimatyzacja</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Układ jezdny */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/serwis/uklad-jezdny')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src="https://test.cms.soft99.eu/uploads/small_Uklad_jezdny_kopiya_ef98046c59.jpg" alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Układ jezdny</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* K: INNE */}

                                    {/* Program lojalnościowy */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/program-lojalnosciowy')}} style={{display: ('Inne' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src={pmp3} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Program lojalnościowy</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Sprzedaż lub zakup auta */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/samochod/')}} style={{display: ('Inne' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src={homepagepromoauto} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Sprzedaż lub zakup auta</p>
                                            </div>
                                        </div>
                                    </div>
                                                         
                                    {/* Szkolenia */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('szkolenie/main')}} style={{display: ('Inne' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src={szkole} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Szkolenia</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Sklep Soft99 */}
                                    <div className="cat-carousel-card col-lg-3 col-md-3 col-sm-3" onClick={()=>{navigate('/kosmetyka/sklep')}} style={{display: ('Inne' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner">
                                            <div className="cat-carousel-card-img">
                                                <img src="https://test.cms.soft99.eu/uploads/small_DSC_06362_dcfbd07de7.png" alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title">
                                                <p>Sklep Soft99</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 mob-cat-box">
                            <div className="cat-car-mob-wrapper row">

                                {/* Myjnia */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/kosmetyka/myjnia')}} style={{display: ('Kosmetyka' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_Myjnia_kopiya_69bd7f3fb5.jpg" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Myjnia</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Felgi i opony */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/serwis/felgi-i-opony')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_Kola_i_opony_kopiya_715eaf37df.jpg" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Felgi i opony</p>
                                        </div>
                                    </div>
                                </div>

                                
                                {/* Pakiet zabezpieczenie */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/kosmetyka/myjnia')}} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={pmp1} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Pakiet zabezpieczenie</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Zmiana opon */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/serwis/felgi-i-opony')}} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            {/* <img src={pmp2} alt="" /> */}
                                            <img src={opony} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Zmiana opon</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Promocje */}
                                {/* <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/promocje')}} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_Kola_i_opony_kopiya_715eaf37df.jpg" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Promocje</p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* Szkolenia */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/promocje')}} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={szkole} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Oferta na ferie</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Detailing */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/kosmetyka/detailing')}} style={{display: ('Kosmetyka' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_Detailing_kopiya_ecd0188c25.jpg" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Detailing</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Sklep Soft99 */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/kosmetyka/sklep')}} style={{display: ('Kosmetyka' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_DSC_06362_dcfbd07de7.png" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Sklep Soft99</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Badania techniczne */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/serwis/badania-techniczne')}} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_Przeglady_okresowe_kopiya_c3714f4899.jpg" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Badania techniczne</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Serwis mechaniczny */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/serwis/serwis-mechaniczny')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_serwei_5a8624ace2.jpg" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Serwis mechaniczny</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Program lojalnościowy */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/program-lojalnosciowy')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.soft99garage.pl/static/media/pl-bgimg.3f2106a02296b8f5bbb4.png" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Program lojalnościowy</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Diagnostyka komputerowa */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/serwis/diagnostyka-komputerowa')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_Diagnostyka_komputerowa_fccc53f051.png" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Diagnostyka komputerowa</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Klimatyzacja */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/serwis/klimatyzacja')}} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_Klimatyzacja_kopiya_6c54433add.jpg" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Klimatyzacja</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Układ jezdny */}
                                <div className="mob-cat-carousel-card col" onClick={()=>{navigate('/serwis/uklad-jezdny')}} style={{display: ('Serwis' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src="https://test.cms.soft99.eu/uploads/small_Uklad_jezdny_kopiya_ef98046c59.jpg" alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Układ jezdny</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="addsection">
                <div className="container">
                    <div className="row">
                        {/* <div className="ak-desk">
                            <img className="ak-img-hp" alt="" src={images[AKdeskLink]}></img>
                        </div>
                        <div className="ak-mob">
                            <img className="ak-img-hp" alt="" src={images[AKmobkLink]}></img>
                        </div> */}
                        {/* <img src={uroaddesk} className="uroaddesk" onClick={()=>{navigate('urodziny')}}></img>
                        <img src={uroadmob} className="uroadmob" onClick={()=>{navigate('urodziny')}}></img> */}
                        <div className="ad-video" onClick={()=>{navigate(menuData.video.path)}}>
                            <video autoPlay muted loop>
                                {menuData && <source src={"https://test.cms.soft99.eu" + menuData.video.video.url} type="video/mp4"></source>}
                            </video>
                            <div className="ad-video-onvideo-text">
                                {menuData && <p className="ad-video-tag">{menuData.video.text}</p>}
                                {menuData && <p className="ad-video-title">{menuData.video.subtext}</p>}
                            </div>
                        </div>
                        <div className="ad-video-mobile" onClick={()=>{navigate(menuData.videoMobile.path)}}>
                            <video autoPlay muted loop>
                                {menuData && <source src={"https://test.cms.soft99.eu" + menuData.videoMobile.video.url} type="video/mp4"></source>}
                            </video>
                            <div className="ad-video-onvideo-text-mobile">
                                {menuData && <p className="ad-video-tag">{menuData.video.text}</p>}
                                {menuData && <p className="ad-video-title">{menuData.video.subtext}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* {menuData && handleMapAds()} */}
                    </div>
                    <div className="carousel-row">
                <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex}
                onRequestChange={setActiveSlideIndex}
                itemsToShow={1}
                itemsToScroll={1}
                forwardBtnProps={{
                  className: "slideForButOpinie",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgf}></img></span>,
                }}
                backwardBtnProps={{
                  className: "slideBacButOpinie",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgb}></img></span>,
                }}
                responsiveProps={[
                  {
                    itemsToShow: 3,
                    itemsToScroll: 1,
                    minWidth: 768,
                  },
                ]}
                speed={400}
                easing="linear"
              >
                
                <div className="slideItemOpinie">
                <div class="package-single-opinie">
                <div class="row">
                <div class="col-lg-3-opinie">
                <img src={ava} className="avatar-image" alt=""></img>
                </div>
                <div class="col-lg-8-opinie">
                <div class="opinie-single-name"><p>Krzysztof</p>
                <div class="row gwiazda-pos">
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  </div>
                </div>
                </div>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="opinie-single-features-item-name">Obsługa klienta na poziomie premium! Usługa wykonana na najwyższym poziomie. Jestem bardzo zadowolony.</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="slideItemOpinie">
                <div class="package-single-opinie">
                <div class="row">
                <div class="col-lg-3-opinie">
                <img src={ava} className="avatar-image" alt=""></img>
                </div>
                <div class="col-lg-8-opinie">
                <div class="opinie-single-name"><p>Jacek</p>
                <div class="row gwiazda-pos">
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  </div>
                </div>
                </div>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="opinie-single-features-item-name">Nigdzie jak dotąd nie spotkałem tak profesjonalnej obsługi myjni. Super wykonanie, super obsługa w trakcie oczekiwania, super efekty po wyjeździe. Mam nadzieję że mnie tam znowu przyjmą. POLECAM!!!</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="slideItemOpinie">
                <div class="package-single-opinie">
                <div class="row">
                <div class="col-lg-3-opinie">
                <img src={ava} className="avatar-image" alt=""></img>
                </div>
                <div class="col-lg-8-opinie">
                <div class="opinie-single-name"><p>Piotr</p>
                <div class="row gwiazda-pos">
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  </div>
                </div>
                </div>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="opinie-single-features-item-name">Jak zawsze usługa wykonana TOP!</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="slideItemOpinie">
                <div class="package-single-opinie">
                <div class="row">
                <div class="col-lg-3-opinie">
                <img src={ava} className="avatar-image" alt=""></img>
                </div>
                <div class="col-lg-8-opinie">
                <div class="opinie-single-name"><p>Michał</p>
                <div class="row gwiazda-pos">
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  </div>
                </div>
                </div>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="opinie-single-features-item-name">Plusem jest to że można załatwić wszystko w jednym miejscu oddałem samochód rano i odebrałem w południe po przeglądzie, wymianie opon , no i autko wyczyszczone po profesjonalnej myjni. Duży plus to hotel dla kół gratis a wymiana w przyzwoitych cenach. POLECAM</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="slideItemOpinie">
                <div class="package-single-opinie">
                <div class="row">
                <div class="col-lg-3-opinie">
                <img src={ava} className="avatar-image" alt=""></img>
                </div>
                <div class="col-lg-8-opinie">
                <div class="opinie-single-name"><p>Tomasz</p>
                <div class="row gwiazda-pos">
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  </div>
                </div>
                </div>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="opinie-single-features-item-name">Fachowa porada, indywidualne podejście do klienta, dbałość o detale</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="slideItemOpinie">
                <div class="package-single-opinie">
                <div class="row">
                <div class="col-lg-3-opinie">
                <img src={ava} className="avatar-image" alt=""></img>
                </div>
                <div class="col-lg-8-opinie">
                <div class="opinie-single-name"><p>Marcin</p>
                <div class="row gwiazda-pos">
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  </div>
                </div>
                </div>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="opinie-single-features-item-name">Pełne zadowolenie z usługi. Obsługa profesjonalna i miła</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="slideItemOpinie">
                <div class="package-single-opinie">
                <div class="row">
                <div class="col-lg-3-opinie">
                <img src={ava} className="avatar-image" alt=""></img>
                </div>
                <div class="col-lg-8-opinie">
                <div class="opinie-single-name"><p>Hieronim</p>
                <div class="row gwiazda-pos">
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  </div>
                </div>
                </div>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="opinie-single-features-item-name">Profesjonalnie wyposażony serwis wymiany opon. Usługa wykonana szybko i sprawnie łącznie z myciem opon i felg. Możliwość przechowania opon. Polecam</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="slideItemOpinie">
                <div class="package-single-opinie">
                <div class="row">
                <div class="col-lg-3-opinie">
                <img src={ava} className="avatar-image" alt=""></img>
                </div>
                <div class="col-lg-8-opinie">
                <div class="opinie-single-name"><p>Michał</p>
                <div class="row gwiazda-pos">
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  <img src={gwi} className="gwiazda-image" alt=""></img>
                  </div>
                </div>
                </div>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="opinie-single-features-item-name">Auto po myjni komplet wygląda jak nowe pełen profesjonalizm. POLECAM</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </ReactSimplyCarousel>
              </div>
                    <div className="row">
                        <div className="col-lg-12 res-box-bottom">
                            <button onClick={() => setOpenSecond(true)}>Zobacz cennik</button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Home;
