import {React, useEffect, useState} from "react";
import Navbar from "./Navbar";
import Package from "./Package";
import Footer from "./Footer";
import { Parallax } from 'react-parallax';
import InnerModal from "./InnerModal";
import InnerModalOpony from "./InnerModalOpony";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import skpad1d from "../img/SKP_myjnia_desktop.png";
import skpad1m from "../img/SKP_myjnia_mobile.png";
import skpad2d from "../img/SKP_gasnica_desktop.png";
import skpad2m from "../img/SKP_gasnica_mobile.png";
import skpad3d from "../img/btdesk.png";
import skpad3m from "../img/btmob.png";
import figad1d from "../img/no99d.png";
import figad1m from "../img/no99d.png";
// import figad2d from "../img/gpa.png";
import figad2d from "../img/wox.png";
import figad2m from "../img/gpam.png";
import figad3d from "../img/Opony_mycie_desktop.png";
import figad3m from "../img/Opony_mycie_mobile.png";
import sgb from "../img/sgb.png";
import sgf from "../img/sgf.png";
import btd1 from "../img/btdX.png";
import btm1 from "../img/btdX.png";
import btd2 from "../img/wmgd.png";
import btm2 from "../img/wmgm.png";
import btd3 from "../img/swiatla_desktop.png";
import btm3 from "../img/swiatla_mobile.png";
import myj1 from "../img/newmyj1.jpg";
import myj2 from "../img/newmyj2.jpg";
import myj3 from "../img/newmyj3.jpg";
import myj4 from "../img/newmyj4.jpg";
import myj5 from "../img/newmyj5.jpg";
import myj6 from "../img/newmyj6.jpg";
import myj7 from "../img/newmyj7.jpg";
import myj8 from "../img/newmyj8.jpg";
import myj9 from "../img/newmyj9.jpg";
import dit1 from "../img/dit1.jpg";
import dit2 from "../img/dit2.jpg";
import dit3 from "../img/dit3.jpg";
import dit4 from "../img/dit4.jpg";
import dit5 from "../img/dit5.jpg";
import dit6 from "../img/dit6.jpg";
import dit7 from "../img/dit7.jpg";
import fol1 from "../img/fol1.jpg";
import fol2 from "../img/fol2.jpg";
import fol3 from "../img/fol3.jpg";
import fol4 from "../img/fol4.jpg";
import fol5 from "../img/fol5.png";
import fol6 from "../img/fol6.png";
import fol7 from "../img/fol7.png";
import { useNavigate } from "react-router-dom";
import ReactSimplyCarousel from 'react-simply-carousel';

const Service = (props) => {
  const navigate = useNavigate();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const {service, services} = props;
  let resBut = '';
  let pricePart = '';
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);
  const [open, setOpen] = useState(false);
  const [openO, setOpenO] = useState(false);
  const baseImgUrl = "https://test.cms.soft99.eu";
  const parallaxBgImg = "https://test.cms.soft99.eu" + service.image.url;
  let mediaBox = 'media box';
  if (service.showReservationButton) {
    resBut = <button className="nav-res-service" onClick={() => setOpen(true)}>Zarezerwuj</button>
  };

  if (service.price !== "") {
    if (service.title == "Pakiet FERIE") {
      pricePart = <span>{service.price} zł</span>
    } else {
      pricePart = <span>od {service.price} zł</span>
    }

  } else {
    pricePart = <span>{service.price}</span>
  }

  if (service.imageBox == null) {
    if (service.videoBox == null) {
      mediaBox = ''
    } else {
      mediaBox = 
        <div className="service-media-part">
          <div className="service-media-part-single">
            <video autoPlay muted loop>
              <source src={baseImgUrl + service.videoBox.video.url} type="video/mp4"></source>
            </video>
            {service.videoBox.text !== null &&
              <p className="service-media-part-single-text">{service.videoBox.text}</p>
            }
          </div>
        </div>
    }
  } else {
    mediaBox = 
      <div className="service-media-part">
        <div className="service-media-part-single">
          <img src={baseImgUrl + service.imageBox.image1[0].url} alt=""></img>
          {service.imageBox.text1 !== null &&
            <p className="service-media-part-single-text">{service.imageBox.text1}</p>
          }
        </div>
        <div className="service-media-part-single">
          <img src={baseImgUrl + service.imageBox.image2[0].url} alt=""></img>
          {service.imageBox.text2 !== null &&
            <p className="service-media-part-single-text">{service.imageBox.text2}</p>
          }
        </div>
      </div>
  };

  return(
        <section>
          <Modal open={open} onClose={() => setOpen(false)} center>
            <InnerModal setOpen={setOpen} service={service && service}/>
          </Modal>
          <Modal open={openO} onClose={() => setOpenO(false)} center>
            <InnerModalOpony setOpenO={setOpenO}/>
          </Modal>
          <header>
            <Navbar services={services} service={service} />
          </header>
          <Parallax bgImage={parallaxBgImg} strength={500} bgClassName="parallax-bg-services">
          <div className="service-top-hero">
            { service.tag != null ?
            <div className="service-top-hero-tag">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <p>{service.tag}</p>
                  </div>
                </div>
              </div>
            </div>
            : null
            }
            <div className="service-top-hero-bottom-part">
              <div className="service-top-hero-title">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 servicetitleholder">
                      <h1>
                        {service.title === 'Protection Time' &&
                          <>Pakiet Protection Time + Przegląd techniczny Gratis</>
                        }
                        {service.title != 'Protection Time' &&
                          service.title
                        }
                      </h1>
                      {/* <h1>{service.title}</h1> */}
                      {/* {service.title === 'Myjnia' &&
                        <p className="ybadge2">Wymiana opon GRATIS!</p>
                      } */}
                      {/* {service.title === 'Felgi i opony' &&
                        <p className="ybadge2">Mycie zewnętrzne GRATIS!</p>
                      } */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
                      <p className="service-top-hero-black-bottom-subtitle">{service.subtitle}</p>
                      <p className="service-top-hero-black-bottom-price">{pricePart}</p>
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                      {service.title === 'Felgi i opony' && 
                        <button className="nav-res-service" onClick={() => setOpenO(true)}>Zarezerwuj</button>
                      }
                    </div>
                  </div>
                </div>
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left">
                        <p className="service-top-hero-black-bottom-subtitle">{service.subtitle}</p>
                        <p className="service-top-hero-black-bottom-price">{pricePart}</p>
                      </div>
                      <div className="service-top-mob-right">
                        {resBut}
                        {service.title === 'Felgi i opony' && 
                        <button className="nav-res-service" onClick={() => setOpenO(true)}>Zarezerwuj</button>
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Parallax>
          {service.title === 'Serwis mechaniczny' &&
          <div>
              <div className="skpad-container-desktop">
                <div className="container">
                  <div className="row">
                    <div className="col-4"><a href={'/serwis/diagnostyka-komputerowa'}><img className="skpad-desktop-image clickme" src={skpad1d} /></a></div>
                    <div className="col-4"><a href={'/serwis/uklad-jezdny'}><img className="skpad-desktop-image clickme" src={skpad2d} /></a></div>
                    <div className="col-4"><a href={'/serwis/badania-techniczne'}><img className="skpad-desktop-image clickme" src={skpad3d} /></a></div>
                  </div>
                </div>
              </div>
              <div className="skpad-container-mobile">
                <div className="container">
                  <div className="row">
                    <div className="col-12"><a href={'/serwis/diagnostyka-komputerowa'}><img className="skpad-desktop-image clickme" src={skpad1m} /></a></div>
                    <div className="col-6"><a href={'/serwis/uklad-jezdny'}><img className="skpad-desktop-image skpad-top-margin-fix clickme" src={skpad2m} /></a></div>
                    <div className="col-6"><a href={'/serwis/badania-techniczne'}><img className="skpad-desktop-image skpad-top-margin-fix clickme" src={skpad3m} /></a></div>
                  </div>
                </div>
              </div>
            </div>
          }
          {service.title === 'Felgi i opony' &&
          <div>
              <div className="skpad-container-desktop">
                <div className="container">
                  <div className="row">
                    <div className="col-4"><img className="skpad-desktop-image" src={figad1d} /></div>
                    <div className="col-4"><img className="skpad-desktop-image" src={figad3d} /></div>
                    <div className="col-4"><img className="skpad-desktop-image" src={figad2d} /></div>
                  </div>
                </div>
              </div>
              <div className="skpad-container-mobile">
                <div className="container">
                  <div className="row">
                    <div className="col-12"><img className="skpad-desktop-image" src={figad1m} /></div>
                    <div className="col-6"><img className="skpad-desktop-image skpad-top-margin-fix" src={figad3m} /></div>
                    {/* <div className="col-6"><img className="skpad-desktop-image skpad-top-margin-fix" src={figad2m} /></div> */}
                  </div>
                </div>
              </div>
            </div>
          }
          {service.title === 'Badania techniczne' &&
          <div>
              <div className="skpad-container-desktop">
                <div className="container">
                  <div className="row">
                    <div className="col-4"><img className="skpad-desktop-image" src={btd1} /></div>
                    <div className="col-4"><img className="skpad-desktop-image" src={btd2} /></div>
                    <div className="col-4"><img className="skpad-desktop-image" src={btd3} /></div>
                  </div>
                </div>
              </div>
              <div className="skpad-container-mobile">
                <div className="container">
                  <div className="row">
                    <div className="col-12"><img className="skpad-desktop-image" src={btm1} /></div>
                    <div className="col-6"><img className="skpad-desktop-image skpad-top-margin-fix" src={btm2} /></div>
                    <div className="col-6"><img className="skpad-desktop-image skpad-top-margin-fix" src={btm3} /></div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="service-main-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  {service.description.split('\n').map(str => <p key={str} className="service-desc-par">{str}</p>)}
                  {service.title === 'Protection Time' &&
                  <>
                    <p className="resbotpt">{resBut}</p>
                    <br /> 
                    <br />            
                    </>     
                  }
                  {service.title === 'Pakiet FERIE' &&
                  <>
                    <p className="resbotpt">{resBut}</p>
                    <br /> 
                    <br />            
                    </>     
                  }
                  {service.title === 'Sklep Soft99' &&
                    <p className="service-desc-par">Możesz też odwiedzić nasz sklep <a href="https://sklep.soft99.eu/pl/" target="_blank">online</a>!</p>
                  }
                </div>
                <div className="col-lg-5">
                  {mediaBox}
                </div>              
              </div>
              <div className="row services-packages-row">
                {service.packages.map((singlePackage)=>{
                    return (
                      <Package key={singlePackage.id} packageName={singlePackage}/>
                    )
                  })
                }
              </div>
              {service.title === 'Myjnia' &&
              <div className="carousel-row">
                <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex}
                onRequestChange={setActiveSlideIndex}
                itemsToShow={1}
                itemsToScroll={1}
                forwardBtnProps={{
                  className: "slideForBut",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgf}></img></span>,
                }}
                backwardBtnProps={{
                  className: "slideBacBut",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgb}></img></span>,
                }}
                responsiveProps={[
                  {
                    itemsToShow: 3,
                    itemsToScroll: 1,
                    minWidth: 768,
                  },
                ]}
                speed={400}
                easing="linear"
              >
                <img src={myj1} className="slideItem" alt=""></img>
                <img src={myj2} className="slideItem" alt=""></img>
                <img src={myj3} className="slideItem" alt=""></img>
                <img src={myj4} className="slideItem" alt=""></img>
                <img src={myj5} className="slideItem" alt=""></img>
                <img src={myj6} className="slideItem" alt=""></img>
                <img src={myj7} className="slideItem" alt=""></img>
                <img src={myj8} className="slideItem" alt=""></img>
                <img src={myj9} className="slideItem" alt=""></img>
              </ReactSimplyCarousel>
              </div>
              }
              {service.title === 'Protection Time' &&
              <div className="carousel-row">
                <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex}
                onRequestChange={setActiveSlideIndex}
                itemsToShow={1}
                itemsToScroll={1}
                forwardBtnProps={{
                  className: "slideForBut",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgf}></img></span>,
                }}
                backwardBtnProps={{
                  className: "slideBacBut",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgb}></img></span>,
                }}
                responsiveProps={[
                  {
                    itemsToShow: 3,
                    itemsToScroll: 1,
                    minWidth: 768,
                  },
                ]}
                speed={400}
                easing="linear"
              >
                <img src={myj1} className="slideItem" alt=""></img>
                <img src={myj2} className="slideItem" alt=""></img>
                <img src={myj3} className="slideItem" alt=""></img>
                <img src={myj4} className="slideItem" alt=""></img>
                <img src={myj5} className="slideItem" alt=""></img>
                <img src={myj6} className="slideItem" alt=""></img>
                <img src={myj7} className="slideItem" alt=""></img>
                <img src={myj8} className="slideItem" alt=""></img>
                <img src={myj9} className="slideItem" alt=""></img>
              </ReactSimplyCarousel>
              </div>
              }
              {service.title === 'Aplikacja folii' &&
              <>
              <div class="row">
              <div class="col-lg-4">
                <div class="package-single-folia">
                <img src={fol5} className="package-image" alt=""></img>
                  <div class="package-single-name"><p>Full Body</p>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="package-single-features-item-name">Wszystkie lakierowane elementy nadwozia (folia PPF, kolor lub mat)</p>
                      </div>
                    </div>
                    <div class="packge-single-aditional-info">
                      <div class="package-single-price">
                        <p>od 10 000 zł</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="package-single-folia">
                <img src={fol6} className="package-image" alt=""></img>
                  <div class="package-single-name"><p>Base Front PPF</p>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="package-single-features-item-name">Kluczowe elementy frontu auta (folia ochronna PPF)</p>
                      </div>
                    </div>
                    <div class="packge-single-aditional-info">
                      <div class="package-single-price">
                        <p>od 2000 zł</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="package-single-folia">
                <div class="package-single-mostpoppular"><p>Hit!</p></div>
                <img src={fol7} className="package-image" alt=""></img>
                  <div class="package-single-name"><p>Custom</p>
                    <div class="package-single-features">
                      <div class="package-single-features-item">
                      <p class="package-single-features-item-name">Twój indywidualny projekt (PPF, kolor lub mat)</p>
                      </div>
                    </div>
                  </div>
                  <div class="packge-single-aditional-info">
                      <div class="package-single-price">
                        <br></br><p>od 300 zł</p>
                      </div>
                    </div>
                </div>
              </div>
              </div>
              <div className="carousel-row">
                <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex}
                onRequestChange={setActiveSlideIndex}
                itemsToShow={1}
                itemsToScroll={1}
                forwardBtnProps={{
                  className: "slideForBut",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgf}></img></span>,
                }}
                backwardBtnProps={{
                  className: "slideBacBut",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgb}></img></span>,
                }}
                responsiveProps={[
                  {
                    itemsToShow: 3,
                    itemsToScroll: 1,
                    minWidth: 768,
                  },
                ]}
                speed={400}
                easing="linear"
              >
                <img src={fol3} className="slideItem" alt=""></img>
                <img src={fol1} className="slideItem" alt=""></img>
                <img src={fol4} className="slideItem" alt=""></img>
                <img src={fol2} className="slideItem" alt=""></img>
              </ReactSimplyCarousel>
              </div>
              <p class="pakiety-font">Pakiety</p>
              <table className="folia-table">
                <thead>
                    <tr>
                        <td>Folie - nadwozie</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody class="folia-table-tbody">
                  <tr><td><h4>Full colour</h4><p>Wszystkie lakierowane elementy auta</p></td><td class="folia-table-tbody">Pakiet przeznaczony dla osób, które chcą całkowicie odmienić oblicze swojego auta poprzez zmianę jego koloru (w tym także folią typu mat). Wyklejenie obejmuje wszystkie lakierowane elementy auta. Dzięki takiemu zabiegowi można uzyskać dowolny efekt kolorystyczny, przy jednoczesnym zabezpieczeniu pierwotnej warstwy lakierniczej. Zabieg jest odwracalny, dzięki czemu w każdej chwili można przywrócić autu fabryczny wygląd.</td></tr>
                  <tr><td><h4>Matte</h4><p>Wszystkie lakierowane elementy auta</p></td><td class="folia-table-tbody">Pakiet zmiany charakterystyki wizualnej auta poprzez aplikację transparentnej folii matowej. Pozwala to utrzymać pierwotny kolor auta, przy zmienianie efektu połysku w mat. Dzięki temu, bazują na palecie kolorystycznej producenta, można uzyskać nietuzinkowy efekt, który z&nbsp;pewnością wyróżni auto spośród innych egzemplarzy spotykanych na drodze.</td></tr>
                  <tr><td><h4>Total PPF</h4><p>Wszystkie lakierowane elementy auta</p></td><td class="folia-table-tbody">Pakiet kompleksowego zabezpieczenia wszystkich elementów lakierowanych bezbarwna folią ochronną (PPF - Pain Protection Film). Bezkompromisowy rodzaj zabezpieczenia aura, dzięki któremu w całości będzie chronione przed uszkodzeniami mechanicznymi, procesami starzenia, agresywną chemią, chemią drogową, warunkami atmosferycznymi oraz zanieczyszczeniami środowiskowymi. Folia PPF nie zmienia wyglądu auta i pozostaje niewidoczna, dzięki czemu jest idealna dla osób chcących utrzymać fabryczny wygląd auta.</td></tr>
                  <tr><td><h4>Base front PPF</h4><p>Zderzak przedni, reflektory przednie, lusterka, front maski</p></td><td class="folia-table-tbody">Podstawowy pakiet zabezpieczający newralgiczne elementy auta narażone na działanie szkodliwych czynników związanych z jazdą, szczególnie w trybie autostradowym. Chronią przed odpryskami, piaskowaniem, wżerami od pozostałości owadów. Ułatwia bieżącą pielęgnację.</td></tr>
                  <tr><td><h4>Full front PPF</h4><p>Zderzak przedni, błotniki przednie, maska, lusterka, słupki&nbsp;A, reflektory przednie</p></td><td class="folia-table-tbody">Pakiet zabezpieczający lakierowane elementy auta, które są najbardziej narażone na uszkodzenia użytkowe - odpryski piaskowanie i szkody parkingowe. Dzięki aplikacji folii ochronnej do minimum zredukowane jest ryzyko uszkodzeń pierwotnej warstwy lakierniczej, a w przypadku reflektorów, degradacji warstwy ochronnej i ich żółknięcia oraz płowienia.</td></tr>
                  <tr><td><h4>Hotspot PPF</h4><p>Reflektory przednie, wnęki klamek drzwiowych, próg załadunkowy bagażnika</p></td><td class="folia-table-tbody">Pakiet zabezpieczenia elementów, które narażone są na najintensywniejsze użytkowanie i najszybciej ulegającą uszkodzeniom, szczególnie w&nbsp;autach nowych lub po wykonanej korekcie. To m.in. wnęki klamek uszkadzane paznokciami lub kluczami, prób załadunkowy uszkadzany bagażem, czy reflektory z tworzywa, które bardzi szybko ulegają degradacji poprzed piaskowanie, odpryski od kamieni i działanie chemii.</td></tr>
                  <tr><td><h4>Dechrom</h4><p>Wszystkie lub wybrane przez klienta chromowane detale</p></td><td class="folia-table-tbody">Pakiet zmian stylistycznych obejmujący zmianę koloru detali i dekorów chromowanych, takich jak grill, blendy zderzaka, emblematy, klamki, czy listwy okienne. Dzięki aplikacji folii w wybranym przez klienta kolorze (najczęściej czarnym) można łatwo pozbyć się niechcianego efektu chromu i jednocześnie zapewnić tym elementom ochronę.</td></tr>
                </tbody>
                <br></br><br></br>
                <thead>
                    <tr>
                        <td>Folie - szyby</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody class="folia-table-tbody">
                  <tr><td><h4>Przyciemnianie szyb</h4><p>Wszystkie szyby tylne (szyby drzwi, szyba tylna, wizjery tylne)</p></td><td class="folia-table-tbody">Pakiet kompleksowego przyciemniania szyb w wybranym przez klienta stopniu (od 50% do 5% przepuszczalności światła)</td></tr>
                  </tbody>
                </table>
              </>
              }
              {service.title === 'Detailing' &&
              <div className="carousel-row">
                <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex}
                onRequestChange={setActiveSlideIndex}
                itemsToShow={1}
                itemsToScroll={1}
                forwardBtnProps={{
                  className: "slideForBut",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgf}></img></span>,
                }}
                backwardBtnProps={{
                  className: "slideBacBut",
                  style: {
                    alignSelf: 'center',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '50%',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '10px',
                    height: 16,
                    lineHeight: 1,
                    textAlign: 'center',
                    width: 16,
                  },
                  children: <span><img src={sgb}></img></span>,
                }}
                responsiveProps={[
                  {
                    itemsToShow: 3,
                    itemsToScroll: 1,
                    minWidth: 768,
                  },
                ]}
                speed={400}
                easing="linear"
              >
                <img src={dit1} className="slideItem" alt=""></img>
                <img src={dit2} className="slideItem" alt=""></img>
                <img src={dit3} className="slideItem" alt=""></img>
                <img src={dit4} className="slideItem" alt=""></img>
                <img src={dit5} className="slideItem" alt=""></img>
                <img src={dit6} className="slideItem" alt=""></img>
                <img src={dit7} className="slideItem" alt=""></img>
              </ReactSimplyCarousel>
              </div>
              }
              {/* {service.title === 'Myjnia' &&
                <div className="row disclaimerrow">
                  <p className="disctext">*z promocji “Wymiana opon gratis” można skorzystać przy zapisie do naszego <a href="/program-lojalnosciowy">programu lojalnosciowego</a>.</p>
                </div>
              }
              {service.title === 'Felgi i opony' &&
                <div className="row disclaimerrow">
                <p className="disctext">*z promocji “Mycie zewnętrzne gratis” można skorzystać przy zapisie do naszego <a href="/program-lojalnosciowy">programu lojalnosciowego</a>.</p>
              </div>
              } */}
              
            </div>
          </div>
          <Footer />
        </section>
    )
};

export default Service;