import {React, useEffect, useState} from "react";
import Navbar from "./Navbar";
import Package from "./Package";
import Footer from "./Footer";
import { Parallax } from 'react-parallax';
import InnerModal from "./InnerModal";
import InnerModalSzkolenie from "./InnerModalSzkolenie";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import skpad1d from "../img/SKP_myjnia_desktop.png";
import skpad1m from "../img/SKP_myjnia_mobile.png";
import skpad2d from "../img/SKP_gasnica_desktop.png";
import skpad2m from "../img/SKP_gasnica_mobile.png";
import skpad3d from "../img/SKP_glaco_desktop.png";
import skpad3m from "../img/SKP_glaco_mobile.png";
import figad1d from "../img/Opony_cena_desktop.png";
import figad1m from "../img/Opony_cena_mobile.png";
import figad2d from "../img/Opony_hotel_desktop.png";
import figad2m from "../img/Opony_hotel_mobile.png";
import figad3d from "../img/Opony_mycie_desktop.png";
import figad3m from "../img/Opony_mycie_mobile.png";
import szb from "../img/nb.png";
import szkbg from "../img/head_desktop.jpg";
import michu from "../img/michu.png";
import szpoz from "../img/sz_poz.mp4";
import ReactSimplyCarousel from 'react-simply-carousel';
import szkol1 from "../img/szkol4.png";
import szkol2 from "../img/szkol5.png";
import szkol3 from "../img/szkol6.png";
import szkol4 from "../img/szkol7.png";
import szkol5 from "../img/szkol8.png";
import szkol6 from "../img/szkol9.png";
import szkol7 from "../img/szkol10.png";
import szkol8 from "../img/szkol11.png";
import szkd1 from "../img/szkd1.png";
import szkd2 from "../img/szkd2.png";
import szkd3 from "../img/szkd3.png";
import szkm1 from "../img/szkm1.png";
import szkm2 from "../img/szkm2.png";
import szkm3 from "../img/szkm3.png";
import sgb from "../img/sgb.png";
import sgf from "../img/sgf.png";
import { useNavigate } from "react-router-dom";
import szkole from "../img/szkole.png";
import pmp1 from "../img/foto.jpg";
import pmp2 from "../img/pmp2.png";
import pmp3 from "../img/pmp3.png";
import indy from "../img/indy.png";
import kore from "../img/kore.png";
import korekta from "../img/korekta.png";
import staz from "../img/staz.png";
import lsp from "../img/michszko.jpg";
import japo from "../img/japojapo.png";
import szkoldron from "../img/szkolenieGaraz.mp4";
import { NavLink } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import lspsl1 from "../img/lsp_sl_1.png";
import lspsl2 from "../img/lsp_sl_2.png";
import lspsl3 from "../img/lsp_sl_3.png";
import lspsl4 from "../img/lsp_sl_4.png";
import lspsl5 from "../img/lsp_sl_5.png";
import lspsl6 from "../img/lsp_sl_6.png";
import lspsl7 from "../img/lsp_sl_7.png";
import lspsl8 from "../img/lsp_sl_8.png";
import lspsl9 from "../img/lsp_sl_9.png";
import lspsl10 from "../img/lsp_sl_10.png";
import lspsl11 from "../img/lsp_sl_11.png";
import lspsl12 from "../img/lsp_sl_12.png";
import lspsl13 from "../img/lsp_sl_13.png";
import lspsl14 from "../img/lsp_sl_14.png";
import lspsl15 from "../img/lsp_sl_15.png";
import lspsl16 from "../img/lsp_sl_16.png";
import lspsl17 from "../img/lsp_sl_17.png";
import lspnewimg from "../img/lsp_new_seb.jpg";

  const Szkolenie = (props) => {
  const {service, services} = props;
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const [currentSelectedCat, setCurrentSelectedCat] = useState('Promo');
  let resBut = '';
  const [isShown, setIsShown] = useState(false);
  const handleClick = event => {
    setIsShown(current => !current);
  };
  const handleClickJaponskie = event => {
    window.location.href="/szkolenie/japonskie";
  };

  const handleClickPakiet = event => {
    window.location.href="/szkolenie/pakiet";
  };

  const handleClickKorekta = event => {
    window.location.href="/szkolenie/korekta";
  };
  const handleClickStaz = event => {
    window.location.href="/szkolenie/staz";
  };
  const handleClickIndywidualne = event => {
    window.location.href="/szkolenie/indywidualne";
  };

  const handleClickLsp = event => {
    window.location.href="/szkolenie/lsp";
  };
  
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [dataSzkolenia, setDataSzkolenia] = useState(null);
  let pricePart = '';
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);

  const catCarouselFilter = (e, catToFilterName) => {
    console.log(e.target);
    // console.log(catToFilterName);
    setCurrentSelectedCat(catToFilterName)
};


  const [open, setOpen] = useState(false);
  const baseImgUrl = "https://test.cms.soft99.eu";
  const parallaxBgImg = szkbg;
  let mediaBox = 'media box';
  if (true) {
    resBut = <button className="nav-res-service" onClick={() => { setDataSzkolenia(null); setOpen(true)}}>Zapisz się</button>
    if(props.active == "indywidualne"){
      resBut = <a href="/kontakt"><button className="nav-res-service">Wyślij zapytanie</button></a>
    } else if (props.active == "staz"){
      resBut = <a href="/kontakt"><button className="nav-res-service" >Chce dołączyć</button></a>
    }
  };

  const Main = (props) => {
    mediaBox = 
        <div className="service-media-part">
           <div className="service-media-part-single">
              <video autoPlay muted loop>
                <source src={szkoldron} type="video/mp4"></source>
              </video>
            </div>
        </div>
    return(
      <div className="service-main-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <p className="service-desc-par szdescpar">
              {/* <b>Pierwsze otwarte szkolenie z podstaw detailingu od Soft99!</b> */}
              Chcesz podnieść swoje kwalifikacje? Chcesz rozbudować swoją wiedzę? Szukasz sposobu, by pracować efektywniej? A może po prostu chcesz rozwijać swoją pasję? Jeśli odpowiedziałeś na choćby jedno z tych pytań twierdząco, to oferta szkoleń w Soft99 Garage jest właśnie dla Ciebie!<br />
              <br />
              Wspólnie z kadrą naszych ekspertów zyskasz wiedzę, zdobędziesz doświadczenie i umiejętność pracy w realnych warunkach. Oddajemy do Twojej dyspozycji 1000m2 nowoczesnej i doskonale wyposażonej przestrzeni, doskonałe materiały szkoleniowe, optymalne programy szkoleń i 100% naszego zaangażowania. Sprawdź ofertę, wybierz swoje szkolenie i zdobądź certyfikację Soft99 – działającej globalnie, uznanej marki z ponad 70-letnią historią.  <br />
              <br />
              
            </p>
          </div>
          <div className="col-lg-5">
            {mediaBox}
          </div>              
        </div>
      </div>
    </div>
    )
  }

  const Pakiet = (props) => {
    mediaBox = 
        <div className="service-media-part">
           <div className="service-media-part-single">
              <video autoPlay muted loop>
                <source src={szpoz} type="video/mp4"></source>
              </video>
            </div>
            <div className="service-media-part-single">
              <br />
              <b className="nb">Kiedy i gdzie?</b>
              <br />
              <br />
              {/* <p className="michtext openszko" onClick={() => { setDataSzkolenia('17.06.2023'); setOpen(true)}}>Poznań | 17.06.2023 + 01.07.2023 + 15.07.2023</p> */}
              <p className="michtext openszko" onClick={() => { setDataSzkolenia('17.06.2023'); setOpen(true)}}>Poznań | 01.07.2023 + 15.07.2023</p>
              <br />
              <b className="nb">Pakiet obejmuje:</b>
              <br />
              <br />
              <p className="michtext">
                {/* 17.06 - Japońskie mycie detailingowe<br /> */}
                01.07 - Korekta lakieru od A do Z<br />
                15.07 - Japońskie LSP<br />
              </p>
            </div>
            <div className="service-media-part-single">
              <br />
              <b className="nb">Nasi detailerzy</b>
              <br />
              <br />
            </div>
            <div className="service-media-part-single">
              <img src={michu} alt=""></img>
              <p className="service-media-part-single-text black-version">Michał Buchwald</p>
            </div>
            <div className="service-media-part-single">
              <p className="michtext">Michał Buchwald jest ekspertem z ponad10-letnim doświadczeniem. Tworzy świat Soft99 w Europie od samego początku, czyli od 2014 roku. Jest Master Detailerem {!isShown && (<span className="readmoreclick" onClick={handleClick}>Czytaj dalej...</span>)}
              {isShown && (
              <span>– człowiekiem, który wie absolutnie wszystko o produktach, zna wszystkie tajniki detailingu i to pod jego czujnym okiem rozwija się europejska oferta Soft99. Jako jeden z niewielu szkolił się w Japonii, pod okiem ekspertów z sieci studiów kosmetyki i detailingu Soft99 Autoservice / G’ZOX, a także przyglądał się procesowi produkcji w fabryce w Sanda. W Soft99 Garage dba o wszystkie aspekty działalności naszego centrum, zarządza zespołem, tworzy i rozwija ofertę, a także szkoli specjalistów. Pasję połączył z pracą i dlatego często możecie go spotkać w strefie detailingu, gdzie doradza klientom, nadzoruje realizację projektów, a często sam prowadzi najbardziej złożone i wymagające projekty.</span>)}<br />{isShown && (<span className="readmoreclick" onClick={handleClick}>Pokaż mniej...</span>)}
              </p>
            </div>
        </div>
    return(
      <div className="service-main-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <p className="service-desc-par szdescpar">
              <b>Pakiet szkoleń detailinowych od Soft99!</b>
              <br />
              <br />
              Nie ma znaczenia, czy dopiero zaczynasz, czy chcesz rozwijać swoją wiedzę i doskonalić umiejętności. Wybierając pakiet 3 szkoleń w Soft99 Garage masz pewność, że bierzesz udział w kompleksowym, opracowanym od podstaw, nowoczesnym programie szkoleniowym pokrywającym wszystkie najważniejsze zagadnienia – od przygotowania do pracy, wyposażenia stanowiska, technik pracy, produktów, technologii, aż po optymalizację czasu. W jednym miejscu otrzymasz wszystko co niezbędne, by rozwinąć skrzydła w branży detailingu.
              <br />
              <br />
              Soft99 Garage to także miejsce napędzane pasją i doświadczeniem ekspertów, którzy na co dzień realizują kompleksowe projekty detailingowe, śledzą trendy, wykorzystują wiedzę oraz dane centrum badawdzo-rozwojowego Soft99 Corporation i z sukcesem tworzą świat Soft99 w Europie. To także ludzie z misją, którzy chcą rozwijać talenty i wspierać innych na każdym etapie ich działalności – od hobbystów, aż po profesjonalistów.
              <br />
              <br />
              <b>Ta oferta jest dla Ciebie jeśli chcesz:</b>
              <br />
              <br />
              <ul>
                  {/* <li>Oszczędzać! Kupując pakiet szkoleń dostajesz rabat 20%! W Twojej kieszeni zostanie aż 440 PLN!</li> */}
                  <li>Oszczędzać! Kupując pakiet szkoleń dostajesz rabat 15%! W Twojej kieszeni zostanie aż 270 PLN!</li>
                  <br />
                  <li>Poznać, zrozumieć i przećwiczyć wszystkie elementy procesu detailingu.</li>
                  <br />
                  <li>Kształcić się w ramach przemyślanego i spójnego programu, w którym zagadnienia się wzajemnie uzupełniają.</li>
                  <br />
                  <li>Cieszyć się niezmiennym, najwyższym standardem podczas wszystkich szkoleń.</li>
                  <br />
                  <li>Poznać tajniki pracy z kompletnym japońskim systemem pracy oraz produktów.</li>
                  <br />
                  <li>Pozyskiwać wiedzę od ekspertów z wieloletnim doświadczeniem w detailingu, ale także w detilingowym biznesie.</li>
                  <br />
              </ul>
              <b>Dlaczego warto się szkolić w Soft99 Garage?</b>
              <br />
              <br />
              <ul>
                  <li>Komfortowa, nowoczesna i doskonale wyposażona przestrzeń.</li>
                  <br />
                  <li>Nowoczesne i optymalne programy szkoleń.</li>
                  <br />
                  <li>Przejrzyste i zrozumiałe materiały dydaktyczne.</li>
                  <br />
                  <li>Kameralna grupa, dzięki której otrzymasz maximum naszej uwagi.</li>
                  <br />
                  <li>Stawiamy na praktykę i działamy w realnych warunkach.</li>
                  <br />
                  <li>Kadra ekspertów doświadczonych w pracy i prowadzeniu detailingowego biznesu.</li>
                  <br />
                  <li>Certyfikujemy jako Soft99 – globalna, uznana marka z 70-letnią historią.</li>
                  <br />
              </ul>
            </p>
          </div>
          <div className="col-lg-5">
            {mediaBox}
          </div>              
        </div>
        <div className="carousel-row">
        <ReactSimplyCarousel
  activeSlideIndex={activeSlideIndex}
  onRequestChange={setActiveSlideIndex}
  itemsToShow={1}
  itemsToScroll={1}
  forwardBtnProps={{
    className: "slideForBut",
    style: {
      alignSelf: 'center',
      background: 'transparent',
      border: 'none',
      borderRadius: '50%',
      color: 'white',
      cursor: 'pointer',
      fontSize: '10px',
      height: 16,
      lineHeight: 1,
      textAlign: 'center',
      width: 16,
    },
    children: <span><img src={sgf}></img></span>,
  }}
  backwardBtnProps={{
    className: "slideBacBut",
    style: {
      alignSelf: 'center',
      background: 'transparent',
      border: 'none',
      borderRadius: '50%',
      color: 'white',
      cursor: 'pointer',
      fontSize: '10px',
      height: 16,
      lineHeight: 1,
      textAlign: 'center',
      width: 16,
    },
    children: <span><img src={sgb}></img></span>,
  }}
  responsiveProps={[
    {
      itemsToShow: 3,
      itemsToScroll: 1,
      minWidth: 768,
    },
  ]}
  speed={400}
  easing="linear"
  >
  <img src={szkol1} className="slideItem" alt=""></img>
  <img src={szkol2} className="slideItem" alt=""></img>
  <img src={szkol3} className="slideItem" alt=""></img>
  <img src={szkol4} className="slideItem" alt=""></img>
  <img src={szkol5} className="slideItem" alt=""></img>
  <img src={szkol6} className="slideItem" alt=""></img>
  <img src={szkol7} className="slideItem" alt=""></img>
  <img src={szkol8} className="slideItem" alt=""></img>
  </ReactSimplyCarousel>
  <div className="aditionalszkoleniebutton"><button className="nav-res-service aditionalszkoleniebuttonbut" onClick={() => { setDataSzkolenia(null); setOpen(true)}}>Zapisz się</button></div>
        </div>
      </div>
    </div>
    )
  }

const Japonskie = (props) => {
  mediaBox = 
      <div className="service-media-part">
         <div className="service-media-part-single">
            <video autoPlay muted loop>
              <source src={szpoz} type="video/mp4"></source>
            </video>
          </div>
          <div className="service-media-part-single">
            <br />
            <b className="nb">Kiedy i gdzie?</b>
            <br />
            <br />
            {/* <p className="michtext brakmiejsc">Poznań | 17 czerwca 2023 - Zapisy zakończone</p> */}
            
            <p className="michtext openszko" onClick={() => { setDataSzkolenia('12.04.2025'); setOpen(true)}}>Poznań | 12 kwietnia 2025</p>
            <p className="michtext openszko" onClick={() => { setDataSzkolenia('24.05.2025'); setOpen(true)}}>Poznań | 24 maja 2025</p>
            {/* <p className="michtext brakmiejsc">Poznań | 2 grudnia 2023</p> */}
            {/* <p className="michtext brakmiejsc" >Poznań | 28 październik 2023 - Zapisy zakończone</p> */}
            <br />
          </div>
          <div className="service-media-part-single">
            <br />
            <b className="nb">Nasi detailerzy</b>
            <br />
            <br />
          </div>
          <div className="service-media-part-single">
            <img src={michu} alt=""></img>
            <p className="service-media-part-single-text black-version">Michał Buchwald</p>
          </div>
          <div className="service-media-part-single">
            <p className="michtext">Michał Buchwald jest ekspertem z ponad10-letnim doświadczeniem. Tworzy świat Soft99 w Europie od samego początku, czyli od 2014 roku. Jest Master Detailerem {!isShown && (<span className="readmoreclick" onClick={handleClick}>Czytaj dalej...</span>)}
            {isShown && (
            <span>– człowiekiem, który wie absolutnie wszystko o produktach, zna wszystkie tajniki detailingu i to pod jego czujnym okiem rozwija się europejska oferta Soft99. Jako jeden z niewielu szkolił się w Japonii, pod okiem ekspertów z sieci studiów kosmetyki i detailingu Soft99 Autoservice / G’ZOX, a także przyglądał się procesowi produkcji w fabryce w Sanda. W Soft99 Garage dba o wszystkie aspekty działalności naszego centrum, zarządza zespołem, tworzy i rozwija ofertę, a także szkoli specjalistów. Pasję połączył z pracą i dlatego często możecie go spotkać w strefie detailingu, gdzie doradza klientom, nadzoruje realizację projektów, a często sam prowadzi najbardziej złożone i wymagające projekty.</span>)}<br />{isShown && (<span className="readmoreclick" onClick={handleClick}>Pokaż mniej...</span>)}
            </p>
          </div>
      </div>
  return(
    <div className="service-main-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-7">
          <p className="service-desc-par szdescpar">
            <b>Pierwsze otwarte szkolenie z podstaw detailingu od Soft99!</b>
            <br />
            <br />
            Soft99® Garage to rewolucyjne centrum obsługi serwisowej rodem z Japonii, po raz pierwszy także w sercu Europy. W jednym miejscu łączymy wszystko, co ważne dla posiadacza samochodu, motocykla i wielu innych pojazdów. Pracujemy przy tym z wykorzystaniem wyłącznie japońskich kosmetyków samochodowych Soft99®, gwarantując najwyższą jakość świadczonych usług.
            <br />
            <br />
            Soft99 Garage to także miejsce napędzane pasją i doświadczeniem ekspertów, którzy na co dzień realizują kompleksowe projekty detailingowe, śledzą trendy, wykorzystują wiedzę oraz dane centrum badawdzo-rozwojowego Soft99 Corporation i z sukcesem tworzą świat Soft99 w Europie. To także ludzie z misją, którzy chcą rozwijać talenty i wspierać innych na każdym etapie ich działalności – od hobbystów, aż po profesjonalistów.
            <br />
            <br />
            <b>To szkolenie jest dla Ciebie jeśli chcesz:</b>
            <br />
            <br />
            <ul>
                <li>Poznać najważniejsze zasady bezpiecznego mycia.</li>
                <br />
                <li>Wiedzieć, jak organizować proces i miejsce pracy.</li>
                <br />
                <li>Zrozumieć problemy, z którymi będziesz się mierzyć.</li>
                <br />
                <li>Poznać japońskie, odmienne spojrzenie na popularne produkty – np. szampon samochodowy.</li>
                <br />
                <li>Optymalizować czas pracy i zwiększyć swoją efektywność.</li>
                <br />
            </ul>
            <b>Co zapewniamy w cenie szkolenia?</b>
            <br />
            <br />
            <ul>
                <li>Imienny certyfikat szkolenia w Soft99 Garage.</li>
                <br />
                <li>Zestaw kosmetyków Soft99 o wartości 200 zł.</li>
                <br />
                <li>Bezpłatne miejsce parkingowe. </li>
                <br />
                <li>Przerwę kawową z poczęstunkiem.</li>
                <br />
                <li>Kameralną grupę, która pozwoli swobodnie zyskiwać wiedzę.</li>
                <br />
                <li>Przewagę praktycznych aspektów – zyskasz realne umiejętności, a nie tylko wiedzę.</li>
                <br />
            </ul>
          </p>
        </div>
        <div className="col-lg-5">
          {mediaBox}
        </div>              
      </div>
      <div className="carousel-row">
      <ReactSimplyCarousel
activeSlideIndex={activeSlideIndex}
onRequestChange={setActiveSlideIndex}
itemsToShow={1}
itemsToScroll={1}
forwardBtnProps={{
  className: "slideForBut",
  style: {
    alignSelf: 'center',
    background: 'transparent',
    border: 'none',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    fontSize: '10px',
    height: 16,
    lineHeight: 1,
    textAlign: 'center',
    width: 16,
  },
  children: <span><img src={sgf}></img></span>,
}}
backwardBtnProps={{
  className: "slideBacBut",
  style: {
    alignSelf: 'center',
    background: 'transparent',
    border: 'none',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    fontSize: '10px',
    height: 16,
    lineHeight: 1,
    textAlign: 'center',
    width: 16,
  },
  children: <span><img src={sgb}></img></span>,
}}
responsiveProps={[
  {
    itemsToShow: 3,
    itemsToScroll: 1,
    minWidth: 768,
  },
]}
speed={400}
easing="linear"
>
<img src={szkol1} className="slideItem" alt=""></img>
<img src={szkol2} className="slideItem" alt=""></img>
<img src={szkol3} className="slideItem" alt=""></img>
<img src={szkol4} className="slideItem" alt=""></img>
<img src={szkol5} className="slideItem" alt=""></img>
<img src={szkol6} className="slideItem" alt=""></img>
<img src={szkol7} className="slideItem" alt=""></img>
<img src={szkol8} className="slideItem" alt=""></img>
</ReactSimplyCarousel>
<div className="aditionalszkoleniebutton"><button className="nav-res-service aditionalszkoleniebuttonbut" onClick={() => { setDataSzkolenia(null); setOpen(true)}}>Zapisz się</button><br/><br/><b>Byłeś już u nas na szkoleniu? Na każde kolejne dostajesz 15% rabatu!</b></div>
      </div>
    </div>
  </div>
  )
}


const Korekta = (props) => {
  mediaBox = 
      <div className="service-media-part">
         <div className="service-media-part-single">
              <img src={korekta} ></img>
          </div>
          <div className="service-media-part-single">
            <br />
            <b className="nb">Kiedy i gdzie?</b>
            <br />
            <br />
            {/* <p className="michtext openszko">Poznań | 28 styczeń 2023 - Zapisy zakończone</p> */}
            {/* <p className="michtext brakmiejsc">Poznań | 1 lipca 2023 - Zapisy zakończone</p> */}
            {/* <p className="michtext brakmiejsc">Poznań | 19 listopad 2023 - Zapisy zakończone</p> */}
            
            {/* <p className="michtext brakmiejsc">Poznań | 9 grudnia 2023 - Zapisy zakończone</p> */}
            <p className="michtext openszko" onClick={() => { setDataSzkolenia('22.04.2025'); setOpen(true)}}>Poznań | 26 kwiecień 2025</p>
            <p className="michtext openszko" onClick={() => { setDataSzkolenia('31.05.2025'); setOpen(true)}}>Poznań | 31 maja 2025</p>
            <br />
          </div>
          {/* <div className="service-media-part-single">
            <br />
            <b className="nb">Cena</b>
            <br />
            <br />
            <p className="michtext openszko">999zł</p>
            <br />
          </div> */}
          <div className="service-media-part-single">
            <br />
            <b className="nb">Nasi detailerzy</b>
            <br />
            <br />
          </div>
          <div className="service-media-part-single">
            <img src={michu} alt=""></img>
            <p className="service-media-part-single-text black-version">Michał Buchwald</p>
          </div>
          <div className="service-media-part-single">
            <p className="michtext">Michał Buchwald jest ekspertem z ponad10-letnim doświadczeniem. Tworzy świat Soft99 w Europie od samego początku, czyli od 2014 roku. Jest Master Detailerem {!isShown && (<span className="readmoreclick" onClick={handleClick}>Czytaj dalej...</span>)}
            {isShown && (
            <span>– człowiekiem, który wie absolutnie wszystko o produktach, zna wszystkie tajniki detailingu i to pod jego czujnym okiem rozwija się europejska oferta Soft99. Jako jeden z niewielu szkolił się w Japonii, pod okiem ekspertów z sieci studiów kosmetyki i detailingu Soft99 Autoservice / G’ZOX, a także przyglądał się procesowi produkcji w fabryce w Sanda. W Soft99 Garage dba o wszystkie aspekty działalności naszego centrum, zarządza zespołem, tworzy i rozwija ofertę, a także szkoli specjalistów. Pasję połączył z pracą i dlatego często możecie go spotkać w strefie detailingu, gdzie doradza klientom, nadzoruje realizację projektów, a często sam prowadzi najbardziej złożone i wymagające projekty.</span>)}<br />{isShown && (<span className="readmoreclick" onClick={handleClick}>Pokaż mniej...</span>)}
            </p>
          </div>
      </div>
  return(
    <div className="service-main-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-7">
          <p className="service-desc-par szdescpar">
            <b>Pierwsze otwarte szkolenie z podstaw detailingu od Soft99!</b>
            <br />
            <br />
            Soft99® Garage to rewolucyjne centrum obsługi serwisowej rodem z Japonii, po raz pierwszy także w sercu Europy. W jednym miejscu łączymy wszystko, co ważne dla posiadacza samochodu, motocykla i wielu innych pojazdów. Pracujemy przy tym z wykorzystaniem wyłącznie japońskich kosmetyków samochodowych Soft99®, gwarantując najwyższą jakość świadczonych usług.
            <br />
            <br />
            Soft99 Garage to także miejsce napędzane pasją i doświadczeniem ekspertów, którzy na co dzień realizują kompleksowe projekty detailingowe, śledzą trendy, wykorzystują wiedzę oraz dane centrum badawdzo-rozwojowego Soft99 Corporation i z sukcesem tworzą świat Soft99 w Europie. To także ludzie z misją, którzy chcą rozwijać talenty i wspierać innych na każdym etapie ich działalności – od hobbystów, aż po profesjonalistów.
            <br />
            <br />
            <b>To szkolenie jest dla Ciebie jeśli chcesz:</b>
            <br />
            <br />
            <ul>
                <li>Uczyć się od doświadczonego eksperta, który zarządza pracą największego centrum pielęgnacji w tej części Europy</li>
                <br />
                <li>Zrozumieć problematykę degradacji lakierów</li>
                <br />
                <li>Zbudować lub rozbudować swoje umiejętności obsługi maszyn polerskich </li>
                <br />
                <li>Poznać techniki pracy, doboru past oraz padów</li>
                <br />
                <li>Działać w praktyce, w realnych warunkach </li>
                <br />
                <li>Wiedzieć, jak organizować proces i miejsce pracy</li>
                <br />
                <li>Optymalizować czas pracy i zwiększyć swoją efektywność</li>
                <br />
                <li>Poznać dobre praktyki i sprawdzone systemy pracy  </li>
                <br />
                <li>Pozyskać wiedzę i umiejętności dopasowane do Twoich oczekiwań </li>
                <br />
            </ul>
            <b>Co zapewniamy w cenie szkolenia?</b>
            <br />
            <br />
            <ul>
                <li>Imienny certyfikat szkolenia w Soft99 Garage.</li>
                <br />
                <li>Zestaw kosmetyków Soft99 o wartości 500 zł.</li>
                <br />
                <li>Bezpłatne miejsce parkingowe. </li>
                <br />
                <li>Przerwę kawową z poczęstunkiem.</li>
                <br />
            </ul>
            <b>Dlaczego warto się szkolić w Soft99 Garage?</b>
            <br />
            <br />
            <ul>
                <li>Komfortowa, nowoczesna i doskonale wyposażona przestrzeń</li>
                <br />
                <li>Nowoczesne i optymalne programy szkoleń </li>
                <br />
                <li>Przejrzyste i zrozumiałe materiały dydaktyczne </li>
                <br />
                <li>Kameralna grupa, dzięki której otrzymasz maximum naszej uwagi </li>
                <br />
                <li>Stawiamy na praktykę i działamy w realnych warunkach  </li>
                <br />
                <li>Kadra ekspertów doświadczonych w pracy i prowadzeniu detailingowego biznesu </li>
                <br />
                <li>Certyfikujemy jako Soft99 – globalna, uznana marka z 70-letnią historią </li>
                <br />
            </ul>
          </p>
        </div>
        <div className="col-lg-5">
          {mediaBox}
        </div>              
      </div>
      <div className="carousel-row">
      <ReactSimplyCarousel
activeSlideIndex={activeSlideIndex}
onRequestChange={setActiveSlideIndex}
itemsToShow={1}
itemsToScroll={1}
forwardBtnProps={{
  className: "slideForBut",
  style: {
    alignSelf: 'center',
    background: 'transparent',
    border: 'none',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    fontSize: '10px',
    height: 16,
    lineHeight: 1,
    textAlign: 'center',
    width: 16,
  },
  children: <span><img src={sgf}></img></span>,
}}
backwardBtnProps={{
  className: "slideBacBut",
  style: {
    alignSelf: 'center',
    background: 'transparent',
    border: 'none',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    fontSize: '10px',
    height: 16,
    lineHeight: 1,
    textAlign: 'center',
    width: 16,
  },
  children: <span><img src={sgb}></img></span>,
}}
responsiveProps={[
  {
    itemsToShow: 3,
    itemsToScroll: 1,
    minWidth: 768,
  },
]}
speed={400}
easing="linear"
>
<img src={szkol1} className="slideItem" alt=""></img>
<img src={szkol2} className="slideItem" alt=""></img>
<img src={szkol3} className="slideItem" alt=""></img>
<img src={szkol4} className="slideItem" alt=""></img>
<img src={szkol5} className="slideItem" alt=""></img>
<img src={szkol6} className="slideItem" alt=""></img>
<img src={szkol7} className="slideItem" alt=""></img>
<img src={szkol8} className="slideItem" alt=""></img>
</ReactSimplyCarousel>
<div className="aditionalszkoleniebutton"><button className="nav-res-service aditionalszkoleniebuttonbut" onClick={() => { setDataSzkolenia(null); setOpen(true)}}>Zapisz się</button><br/><br/><b>Byłeś już u nas na szkoleniu? Na każde kolejne dostajesz 15% rabatu!</b></div>
      </div>
    </div>
  </div>
  )
}

const Lsp = (props) => {
  mediaBox = 
      <div className="service-media-part">
         <div className="service-media-part-single">
              <img src={lspnewimg} ></img>
          </div>
          <div className="service-media-part-single">
            <br />
            <b className="nb">Kiedy i gdzie?</b>
            <br />
            <br />
            {/* <p className="michtext openszko">Poznań | 28 styczeń 2023 - Zapisy zakończone</p> */}
            {/* <p className="michtext brakmiejsc">Poznań | 25 listopad 2023 - Zapisy zakończone</p> */}
            <p className="michtext openszko" onClick={() => { setDataSzkolenia('05.04.2025'); setOpen(true)}}>Poznań | 5 kwiecień 2025</p>
            <p className="michtext openszko" onClick={() => { setDataSzkolenia('10.05.2025'); setOpen(true)}}>Poznań | 10 maj 2025</p>
            <br />
          </div>
          {/* <div className="service-media-part-single">
            <br />
            <b className="nb">Cena</b>
            <br />
            <br />
            <p className="michtext openszko">999zł</p>
            <br />
          </div> */}
          <div className="service-media-part-single">
            <br />
            <b className="nb">Nasi detailerzy</b>
            <br />
            <br />
          </div>
          <div className="service-media-part-single">
            <img src={michu} alt=""></img>
            <p className="service-media-part-single-text black-version">Michał Buchwald</p>
          </div>
          <div className="service-media-part-single">
            <p className="michtext">Michał Buchwald jest ekspertem z ponad 10-letnim doświadczeniem. Tworzy świat Soft99 w Europie od samego początku, czyli od 2014 roku. Jest Master Detailerem {!isShown && (<span className="readmoreclick" onClick={handleClick}>Czytaj dalej...</span>)}
            {isShown && (
            <span>– człowiekiem, który wie absolutnie wszystko o produktach, zna wszystkie tajniki detailingu i to pod jego czujnym okiem rozwija się europejska oferta Soft99. Jako jeden z niewielu szkolił się w Japonii, pod okiem ekspertów z sieci studiów kosmetyki i detailingu Soft99 Autoservice / G’ZOX, a także przyglądał się procesowi produkcji w fabryce w Sanda. W Soft99 Garage dba o wszystkie aspekty działalności naszego centrum, zarządza zespołem, tworzy i rozwija ofertę, a także szkoli specjalistów. Pasję połączył z pracą i dlatego często możecie go spotkać w strefie detailingu, gdzie doradza klientom, nadzoruje realizację projektów, a często sam prowadzi najbardziej złożone i wymagające projekty.</span>)}<br />{isShown && (<span className="readmoreclick" onClick={handleClick}>Pokaż mniej...</span>)}
            </p>
          </div>
      </div>
  return(
    <div className="service-main-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-7">
          <p className="service-desc-par szdescpar">
            <b>Japońskie LSP, czyli zabezpieczenie powierzchni z Soft99</b>
            <br />
            <br />
            Last Stage Protection (LSP), czyli ostatni krok w procesie detailingu. To ukoronowanie wysiłku, dzięki któremu każdy element auta jest perfekcyjnie czysty i lśni. Zabezpieczenie ma za zadanie podkreślić blask i utrzymać efekt, by właściciel pojazdu mógł cieszyć się z wykonanej usługi możliwie długo. 
            <br />
            <br />
            Lakier, szyby, tworzywa, elementy chromowane, felgi oraz opony, a do tego kilka różnych technologii zabezpieczeń. Woski naturalne, hybrydowe, sealanty, powłoki silikonowe, polimerowe i kwarcowe. Soft99 to aż kilkadziesiąt produktów do zabezpieczenia i technologiczne doświadczenie sięgające 1954 roku.  Jak nie zgubić się w tak rozbudowanym świecie? Jak dobierać odpowiednie rozwiązania do potrzeb i oczekiwań? Jak aplikować poszczególne środki i na co zwracać uwagę? 
            <br />
            <br />
            Tego wszystkiego dowiesz się na naszym szkoleniu!     
            <br />
            <br />
            <b>To szkolenie jest dla Ciebie jeśli chcesz:</b>
            <br />
            <br />
            <ul>
                <li>Uczyć się od doświadczonego eksperta, który zarządza pracą największego centrum pielęgnacji w tej części Europy i tworzy ofertę Soft99 w Europie</li>
                <br />
                <li>Poznać kompletne spektrum rozwiązań do zabezpieczenia powierzchni – woski, powłoki kwarcowe QJUTSU, rodzinę powłok hydrofobowych do szyb Glaco i wiele innych </li>
                <br />
                <li>Nauczyć się doboru właściwych rozwiązań i optymalizacji swojej pracy</li>
                <br />
                <li>Poznać różne techniki aplikacji oraz triki niezbędne w pracy profesjonalisty</li>
                <br />
                <li>Rozumieć chemiczne aspekty poszczególnych technologii</li>
                <br />
                <li>Budować swoją przewagę w oparciu o jedną z najszerszych ofert produktowych na rynku</li>
                <br />
                <li>Działać w praktyce, w realnych warunkach</li>
                <br />
                <li>Wiedzieć, jak organizować proces i miejsce pracy</li>
                <br />
                <li>Optymalizować czas pracy i zwiększyć swoją efektywność</li>
                <br />
                <li>Pozyskać wiedzę i umiejętności dopasowane do Twoich oczekiwań</li>
                <br />
            </ul>
            <b>Co zapewniamy w cenie szkolenia?</b>
            <br />
            <br />
            <ul>
                <li>Imienny certyfikat szkolenia w Soft99 Garage.</li>
                <br />
                <li>Zestaw kosmetyków Soft99 o wartości 400 zł</li>
                <br />
                <li>Bezpłatne miejsce parkingowe. </li>
                <br />
                <li>Przerwę kawową z poczęstunkiem.</li>
                <br />
            </ul>
            <b>Dlaczego warto się szkolić w Soft99 Garage?</b>
            <br />
            <br />
            <ul>
                <li>Komfortowa, nowoczesna i doskonale wyposażona przestrzeń</li>
                <br />
                <li>Nowoczesne i optymalne programy szkoleń </li>
                <br />
                <li>Przejrzyste i zrozumiałe materiały dydaktyczne </li>
                <br />
                <li>Kameralna grupa, dzięki której otrzymasz maximum naszej uwagi </li>
                <br />
                <li>Stawiamy na praktykę i działamy w realnych warunkach  </li>
                <br />
                <li>Kadra ekspertów doświadczonych w pracy i prowadzeniu detailingowego biznesu </li>
                <br />
                <li>Certyfikujemy jako Soft99 – globalna, uznana marka z 70-letnią historią </li>
                <br />
            </ul>
          </p>
        </div>
        <div className="col-lg-5">
          {mediaBox}
        </div>              
      </div>
      <div className="carousel-row">
      <ReactSimplyCarousel
activeSlideIndex={activeSlideIndex}
onRequestChange={setActiveSlideIndex}
itemsToShow={1}
itemsToScroll={1}
forwardBtnProps={{
  className: "slideForBut",
  style: {
    alignSelf: 'center',
    background: 'transparent',
    border: 'none',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    fontSize: '10px',
    height: 16,
    lineHeight: 1,
    textAlign: 'center',
    width: 16,
  },
  children: <span><img src={sgf}></img></span>,
}}
backwardBtnProps={{
  className: "slideBacBut",
  style: {
    alignSelf: 'center',
    background: 'transparent',
    border: 'none',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    fontSize: '10px',
    height: 16,
    lineHeight: 1,
    textAlign: 'center',
    width: 16,
  },
  children: <span><img src={sgb}></img></span>,
}}
responsiveProps={[
  {
    itemsToShow: 3,
    itemsToScroll: 1,
    minWidth: 768,
  },
]}
speed={400}
easing="linear"
>
<img src={lspsl1} className="slideItem" alt=""></img>
<img src={lspsl2} className="slideItem" alt=""></img>
<img src={lspsl3} className="slideItem" alt=""></img>
<img src={lspsl4} className="slideItem" alt=""></img>
<img src={lspsl5} className="slideItem" alt=""></img>
<img src={lspsl6} className="slideItem" alt=""></img>
<img src={lspsl7} className="slideItem" alt=""></img>
<img src={lspsl8} className="slideItem" alt=""></img>
<img src={lspsl9} className="slideItem" alt=""></img>
<img src={lspsl10} className="slideItem" alt=""></img>
<img src={lspsl11} className="slideItem" alt=""></img>
<img src={lspsl12} className="slideItem" alt=""></img>
<img src={lspsl13} className="slideItem" alt=""></img>
<img src={lspsl14} className="slideItem" alt=""></img>
<img src={lspsl15} className="slideItem" alt=""></img>
<img src={lspsl16} className="slideItem" alt=""></img>
<img src={lspsl17} className="slideItem" alt=""></img>
</ReactSimplyCarousel>
<div className="aditionalszkoleniebutton"><button className="nav-res-service aditionalszkoleniebuttonbut" onClick={() => { setDataSzkolenia(null); setOpen(true)}}>Zapisz się</button><br/><br/><b>Byłeś już u nas na szkoleniu? Na każde kolejne dostajesz 15% rabatu!</b></div>
      </div>
    </div>
  </div>
  )
}

const Indywidualne = (props) => {
  mediaBox = 
      <div className="service-media-part">
         <div className="service-media-part-single">
            <video autoPlay muted loop>
              <source src={szpoz} type="video/mp4"></source>
            </video>
          </div>
      </div>
  return(
    <div className="service-main-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-7">
          <p className="service-desc-par szdescpar">
            {/* <b>Pierwsze otwarte szkolenie z podstaw detailingu od Soft99!</b> */}
            Gotowe programy szkoleń nie odpowiadają Twoim oczekiwaniom i masz specyficzne potrzeby w zakresie pozyskania wiedzy? A może poszukujesz szkoleń dla swoich pracowników, które obejmą konkretne zagadnienia?  Koniecznie skontaktuj się z nami! <br />
            <br></br>Prowadzimy indywidualne szkolenia dla osób lub grup tworząc programy od podstaw, uszyty na miarę Twoich oczekiwań. Realizujemy działania edukacyjne dla zespołów myjni, car-spa i studiów detailingu na każdym poziomie zaawansowania – od wdrożenia, przez szlifowanie umiejętności, aż po prowadzenie złożonych projektów detailingowych. <br />
            <a href="/kontakt"><button className="nav-res-service">WYŚLIJ ZAPYTANIE</button></a><br /><br />
          </p>
        </div>
        <div className="col-lg-5">
        <div className="service-media-part-single">
            <b className="nb">Nasi detailerzy</b>
            <br />
            <br />
          </div>
          <div className="service-media-part-single">
            <img src={michu} alt=""></img>
            <p className="service-media-part-single-text black-version">Michał Buchwald</p>
          </div>
          <div className="service-media-part-single">
            <p className="michtext">Michał Buchwald jest ekspertem z ponad10-letnim doświadczeniem. Tworzy świat Soft99 w Europie od samego początku, czyli od 2014 roku. Jest Master Detailerem {!isShown && (<span className="readmoreclick" onClick={handleClick}>Czytaj dalej...</span>)}
            {isShown && (
            <span>– człowiekiem, który wie absolutnie wszystko o produktach, zna wszystkie tajniki detailingu i to pod jego czujnym okiem rozwija się europejska oferta Soft99. Jako jeden z niewielu szkolił się w Japonii, pod okiem ekspertów z sieci studiów kosmetyki i detailingu Soft99 Autoservice / G’ZOX, a także przyglądał się procesowi produkcji w fabryce w Sanda. W Soft99 Garage dba o wszystkie aspekty działalności naszego centrum, zarządza zespołem, tworzy i rozwija ofertę, a także szkoli specjalistów. Pasję połączył z pracą i dlatego często możecie go spotkać w strefie detailingu, gdzie doradza klientom, nadzoruje realizację projektów, a często sam prowadzi najbardziej złożone i wymagające projekty.</span>)}<br />{isShown && (<span className="readmoreclick" onClick={handleClick}>Pokaż mniej...</span>)}
            </p>
          </div>
        </div>              
      </div>
    </div>
  </div>
  )
}
const Staz = (props) => {
  mediaBox = 
      <div className="service-media-part">
         <div className="service-media-part-single">
            <video autoPlay muted loop>
              <source src={szpoz} type="video/mp4"></source>
            </video>
          </div>
      </div>
  return(
    <div className="service-main-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-7">
          <p className="service-desc-par szdescpar">
            {/* <b>Pierwsze otwarte szkolenie z podstaw detailingu od Soft99!</b> */}
            Jeśli poszukujesz swojej pierwszej pracy i marzysz o karierze w branży kosmetyki samochodowej, to tę przygodę warto zacząć w Soft99 Garage. Dołącz do naszego zespołu jako stażysta, a my wdrożymy Cię w procedury, pokażemy jak funkcjonuje największe w tej części Europy, kompleksowe centrum samochodowej kosmetyki i nauczymy Cię podstaw pracy.  <br />
            <br />Z nami zyskasz pierwsze doświadczenia i rozwiniesz skrzydła pod szyldem jednej z największych, globalnych i uznanych marek w segmencie car-care, czyli Soft99!  <br />
            <br /><a href="/kontakt"><button className="nav-res-service">CHCE DOŁĄCZYĆ</button></a><br /><br />
          </p>
        </div>
        <div className="col-lg-5">
        <div className="service-media-part-single">
            <b className="nb">Nasi detailerzy</b>
            <br />
            <br />
          </div>
          <div className="service-media-part-single">
            <img src={michu} alt=""></img>
            <p className="service-media-part-single-text black-version">Michał Buchwald</p>
          </div>
          <div className="service-media-part-single">
            <p className="michtext">Michał Buchwald jest ekspertem z ponad10-letnim doświadczeniem. Tworzy świat Soft99 w Europie od samego początku, czyli od 2014 roku. Jest Master Detailerem {!isShown && (<span className="readmoreclick" onClick={handleClick}>Czytaj dalej...</span>)}
            {isShown && (
            <span>– człowiekiem, który wie absolutnie wszystko o produktach, zna wszystkie tajniki detailingu i to pod jego czujnym okiem rozwija się europejska oferta Soft99. Jako jeden z niewielu szkolił się w Japonii, pod okiem ekspertów z sieci studiów kosmetyki i detailingu Soft99 Autoservice / G’ZOX, a także przyglądał się procesowi produkcji w fabryce w Sanda. W Soft99 Garage dba o wszystkie aspekty działalności naszego centrum, zarządza zespołem, tworzy i rozwija ofertę, a także szkoli specjalistów. Pasję połączył z pracą i dlatego często możecie go spotkać w strefie detailingu, gdzie doradza klientom, nadzoruje realizację projektów, a często sam prowadzi najbardziej złożone i wymagające projekty.</span>)}<br />{isShown && (<span className="readmoreclick" onClick={handleClick}>Pokaż mniej...</span>)}
            </p>
          </div>
        </div>             
      </div>
    </div>
  </div>
  )
}
  return(
        <section>
          <Modal open={open} onClose={() => setOpen(false)} center>
            <InnerModalSzkolenie dataszko={dataSzkolenia}/>
          </Modal>
          <header>
            <Navbar services={services} service={service} szkoleniejest={true} />
          </header>
          <Parallax bgImage={parallaxBgImg} strength={500} bgClassName="parallax-bg-services">
          <div className="service-top-hero">
            <div className="service-top-hero-tag">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <p>{service.tag}</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="service-top-hero-bottom-part">
              <div className="service-top-hero-title">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2>Szkolenia</h2>
                    </div>
                  </div>
                </div>
              </div>
              {props.active === "japonskie" && 
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
                      <p className="service-top-hero-black-bottom-subtitle">Japońskie mycie detailingowe</p>
                      <span className="service-top-hero-black-bottom-price">399 zł</span>
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                    </div>
                  </div>
                </div>
              
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left tszctap">
                        <p>Japońskie mycie <br></br>detailingowe</p>
                        <span className="service-top-hero-black-bottom-price">399 zł</span>
                        {/* <span className="pst">899 zł</span> <span className="pr">399 zł</span> */}
                      </div>
                      <div className="service-top-mob-right tszcta">
                        {resBut}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {props.active === "pakiet" && 
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
                      <p className="service-top-hero-black-bottom-subtitle">Pakiet szkoleń w Soft99 Garage</p>
                       {/* <span className="pst">899 zł</span> <span className="pr">399 zł</span> */}
                       {/* <span className="service-top-hero-black-bottom-price pst">2197 zł</span> <span className="service-top-hero-black-bottom-price pr">1757 zł</span> <span></span> */}
                       <span className="service-top-hero-black-bottom-price pst">1798 zł</span> <span className="service-top-hero-black-bottom-price pr">1528 zł</span> <span></span>
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                    </div>
                  </div>
                </div>
              
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left tszctap">
                        <p>Pakiet szkoleń w Soft99 Garage</p>
                        <span className="service-top-hero-black-bottom-price pst">1798 zł</span> <span className="service-top-hero-black-bottom-price pr">1528 zł</span> <span></span>
                        {/* <span className="service-top-hero-black-bottom-price pst">2197 zł</span> <span className="service-top-hero-black-bottom-price pr">1757 zł</span> <span></span> */}
                      </div>
                      <div className="service-top-mob-right tszcta">
                        {resBut}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {props.active === "korekta" && 
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
                      <p className="service-top-hero-black-bottom-subtitle">Korekta lakieru od A do Z</p>
                      <span className="service-top-hero-black-bottom-price">999 zł</span> <span></span>
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                    </div>
                  </div>
                </div>
              
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left tszctap">
                        <p>Korekta lakieru od A do Z</p>
                        <span className="service-top-hero-black-bottom-price">999 zł</span>
                      </div>
                      <div className="service-top-mob-right tszcta">
                        {resBut}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {props.active === "indywidualne" && 
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
                      <p className="service-top-hero-black-bottom-subtitle">Szkolenie indywidualne</p>
                      <span className="service-top-hero-black-bottom-price">Do uzgodnienia</span>
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                    </div>
                  </div>
                </div>
              
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left tszctap">
                        <p>Szkolenie indywidualne</p>
                        <span className="service-top-hero-black-bottom-price">Do uzgodnienia</span>
                        {/* <span className="pst">899 zł</span> <span className="pr">399 zł</span> */}
                      </div>
                      <div className="service-top-mob-right tszcta">
                        {resBut}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {props.active === "lsp" && 
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
                      <p className="service-top-hero-black-bottom-subtitle">Japońskie LSP, czyli zabezpieczenie powierzchni z Soft99</p>
                      <span className="service-top-hero-black-bottom-price">799 zł</span>
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                    </div>
                  </div>
                </div>
              
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left tszctap">
                        <p>Japońskie LSP, czyli zabezpieczenie powierzchni z Soft99</p>
                        <span className="service-top-hero-black-bottom-price">799 zł</span>
                        {/* <span className="pst">899 zł</span> <span className="pr">399 zł</span> */}
                      </div>
                      <div className="service-top-mob-right tszcta">
                        {resBut}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {props.active === "staz" && 
              <div className="service-top-hero-black-bottom">
                <div className="container dontshowmeonmobile">
                  <div className="row">
                    <div className="col-lg-6 col-sm-8">
                      <p className="service-top-hero-black-bottom-subtitle">Praca w Soft99!</p>
                      {/* <span className="service-top-hero-black-bottom-price">Do uzgodnienia</span> */}
                    </div>
                    <div className="col-lg-6 col-sm-4">
                      {resBut}
                    </div>
                  </div>
                </div>
              
                <div className="container showmeonlyonmobile">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-top-mob-left tszctap">
                        <p>Praca w Soft99!</p>
                        {/* <span className="service-top-hero-black-bottom-price">899 zł</span> */}
                        {/* <span className="pst">899 zł</span> <span className="pr">399 zł</span> */}
                      </div>
                      <div className="service-top-mob-right tszcta">
                        {resBut}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            </div>
          </div>
          </Parallax>
          <div>
          <section className="black-section homepage-welcome-screen">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 cat-box">
                            <div className="cat-carousel">

                                <div className="cat-carousel-cards row">
                                    <div className="cat-carousel-card-szkolenie col-lg-3 col-md-3 col-sm-3" onClick={handleClickJaponskie} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner-szkolenie">
                                            <div className="cat-carousel-card-img-szkolenie">
                                                <img src={japo} alt="" />
                                            </div>
                                            
                                            <div className="cat-carousel-card-title-szkolenie">
                                                <p>Japońskie mycie detailingowe</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cat-carousel-card-szkolenie col-lg-3 col-md-3 col-sm-3" onClick={handleClickKorekta} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner-szkolenie">
                                            <div className="cat-carousel-card-img-szkolenie">
                                              {/* <p className="ybadge-szkolenie">Nowość!</p> */}
                                                <img src={kore} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title-szkolenie">
                                                <p>Korekta lakieru od A do Z</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cat-carousel-card-szkolenie col-lg-3 col-md-3 col-sm-3" onClick={handleClickLsp} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner-szkolenie">
                                            <div className="cat-carousel-card-img-szkolenie">
                                                <img src={lsp} alt="" />
                                            </div>
                                            
                                            <div className="cat-carousel-card-title-szkolenie">
                                                <p>Japońskie LSP</p>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="cat-carousel-card-szkolenie col-lg-3 col-md-3 col-sm-3" onClick={handleClickPakiet} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner-szkolenie">
                                            <div className="cat-carousel-card-img-szkolenie">
                                                <img src={indy} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title-szkolenie">
                                                <p>Pakiet szkoleń</p>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="cat-carousel-card-szkolenie col-lg-3 col-md-3 col-sm-3" onClick={handleClickIndywidualne} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner-szkolenie">
                                            <div className="cat-carousel-card-img-szkolenie">
                                                <img src={indy} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title-szkolenie">
                                                <p>Szkolenie indywidualne</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="cat-carousel-card-szkolenie col-lg-3 col-md-3 col-sm-3" onClick={handleClickStaz} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                        <div className="cat-carousel-card-inner-szkolenie">
                                            <div className="cat-carousel-card-img-szkolenie">
                                                <img src={staz} alt="" />
                                            </div>
                                            <div className="cat-carousel-card-title-szkolenie">
                                                <p>Staż</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 mob-cat-box">
                            <div className="cat-car-mob-wrapper row">
                                <div className="mob-cat-carousel-card col" onClick={handleClickJaponskie} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner-szkolenie">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={japo} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Japońskie mycie detailingowe</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mob-cat-carousel-card col" onClick={handleClickKorekta} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={kore} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Korekta lakieru<br></br> od A do Z</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mob-cat-carousel-card col" onClick={handleClickLsp} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner-szkolenie">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={lsp} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Japońskie LSP</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="mob-cat-carousel-card col" onClick={handleClickIndywidualne} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={indy} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Pakiet<br></br> szkoleń</p>
                                        </div>
                                    </div>
                                </div>
{/* 
                                <div className="mob-cat-carousel-card col" onClick={handleClickIndywidualne} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={indy} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Szkolenie<br></br> indywidualne</p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="mob-cat-carousel-card col" onClick={handleClickStaz} style={{display: ('Promo' === currentSelectedCat || currentSelectedCat === 'Wszystko') ? 'initial' : 'none' }}>
                                    <div className="mob-cat-carousel-card-inner">
                                        <div className="mob-cat-carousel-card-img">
                                            <img src={staz} alt="" />
                                        </div>
                                        <div className="mob-cat-carousel-card-title">
                                            <p>Staż</p>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
          {props.active === "main" && <Main />}
          {props.active === "japonskie" && <Japonskie />}
          {props.active === "korekta" && <Korekta />}
          {props.active === "indywidualne" && <Indywidualne />}
          {props.active === "staz" && <Staz />}
          {props.active === "lsp" && <Lsp />}
          {props.active === "pakiet" && <Pakiet />}
          <Footer />
        </section>
    )
};

export default Szkolenie;